import './App.css';
import logo from './images/logo.png'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Cloud from './pages/Services/Cloud/Cloud';
import Analytics from './pages/Services/Analytics/Analytics';
import CyberSecurity from './pages/Services/CyberSecurity/CyberSecurity';
import Software from './pages/Services/Software/Software';
import Migrate from './pages/Services/Migrate/Migrate';
import Devops from './pages/Services/Devops/Devops';
import DigitalMarketing from './pages/Services/DigitalMarketing/DigitalMarketing';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import FAQ from './pages/FAQ/FAQ';
import ContactUsPage from './pages/ContactUs/ContactUsPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import OurTeam from './pages/OurTeam/OurTeam';
import LegalNotice from './pages/LegalNotice/LegalNotice';

import instagramIcon from './images/homepage/instagramIcon.svg'
import facebookIcon from './images/homepage/facebookIcon.svg'
import linkedInIcon from './images/homepage/linkedInIcon.svg'
import youtubeIcon from './images/homepage/youtubeIcon.svg'

import conditionIcon from './images/homepage/legalIcon.svg'
import policyIcon from './images/homepage/privacyIcon.svg'
import mailIcon from './images/homepage/mailIcon.svg'
import locationIcon from './images/homepage/location.svg'
import whatsappIcon from './images/homepage/whatsappIcon.svg'
import { Helmet } from 'react-helmet';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Services from './pages/Services/Services';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { useState } from 'react';

function App() {

  // const handleLinkClick = (to) => {
  //   setToggleNav(false)
  // };

  console.log('app');
  const [activeLink, setActiveLink] = useState('/');

  // useEffect(() => {
  //   const browser = bowser.getParser(window.navigator.userAgent);
  //   const browserName = browser.getBrowserName().toLowerCase();
    
  //   // Load browser-specific CSS dynamically
  //   if(browserName === 'firefox'){
  //   import(`./firefox-styles.css`)
  //     .then(() => console.log(`${browserName}-styles.css loaded successfully`))
  //     .catch(error => console.error(`Error loading ${browserName}-styles.css:`, error));
  //   }
  // }, []);

  
  return (
    <BrowserRouter >
      <div className="App bg-color" >
      <Helmet>
        <meta name='title' data-react-helmet="true" property="og:title" content="Nimbusbytes, an information technology (IT) services provider" />
        <meta name='description' data-react-helmet="true" property="og:description" content="Nimbusbytes is a leading information technology (IT) services company offering customized IT solutions." />
      </Helmet>
        <Header setActiveLink={setActiveLink}  activeLink={activeLink}/>
        <Routes>
          <Route path='/' element={<Home setActiveLink={setActiveLink}  />} />
          <Route path='/our-team' element={<OurTeam setActiveLink={setActiveLink} />} />
          <Route path='/about' element={<AboutUs setActiveLink={setActiveLink} />} />
          <Route path='/services' element={<Services setActiveLink={setActiveLink} />} />
          <Route path='/services/cloud' element={<Cloud setActiveLink={setActiveLink} />} />
          <Route path='/services/analytics' element={<Analytics setActiveLink={setActiveLink} />} />
          <Route path='/services/cybersecurity' element={<CyberSecurity setActiveLink={setActiveLink} />} />
          <Route path='/services/software-services' element={<Software setActiveLink={setActiveLink} />} />
          <Route path='/services/migrate-modernize' element={<Migrate setActiveLink={setActiveLink} />} />
          <Route path='/services/devops' element={<Devops setActiveLink={setActiveLink} />} />
          <Route path='/services/digital-marketing' element={<DigitalMarketing setActiveLink={setActiveLink} />} />
          <Route path='/faq' element={<FAQ setActiveLink={setActiveLink} />} />
          <Route path='/contactUs' element={<ContactUsPage setActiveLink={setActiveLink} />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy setActiveLink={setActiveLink} />} />
          <Route path='/legal-notice' element={<LegalNotice setActiveLink={setActiveLink} />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>

        <ScrollToTop />

        <footer>
          <div className="footer-container">
            <div className="footer__top">
              <div className="footer__top_left">
                <img src={logo} alt="nimbusbytes" className="footer__logo" />
                <p>We help global leaders with their organization's most critical issues and opportunities. Together, we create enduring change and results.</p>
              </div>
              <div className="footer__services">
                <h3>Services</h3>
                <div className='footer__services_container'>
                  <ul>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/cloud">Cloud Managed services</Link></li>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/analytics">Data and Analytics</Link></li>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/software-services">Software Development services</Link></li>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/digital-marketing">Digital Marketing</Link></li>
                  </ul>
                  <ul>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/cybersecurity"> Cybersecurity</Link></li>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/devops">DevSecOps and SRE Service</Link></li>
                    <li><Link onClick={() => window.scrollTo(0,0) } to="/services/migrate-modernize">Migrate and Modernize</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer__middle">
              <div className="footer__middle_container">
                <h3>Get in Touch</h3>

                <div className='footer__middle_cont'>
                  <ul>
                    <li> <div className='footer__middle_img'><img src={locationIcon} alt='nimbusbytes' /></div> <Link  to="https://maps.app.goo.gl/sJZcVJQfJiwUaZU56">NimbusBytes Private Limited, India</Link></li>
                    <li> <div className='footer__middle_img'><img src={mailIcon} alt='nimbusbytes' /></div> <Link to="mailto:care@nimbusbytes.com">care@nimbusbytes.com</Link></li>
                    <li> <div className='footer__middle_img'><img src={whatsappIcon} alt='nimbusbytes' /></div> <Link to="tel:6283343851">Whatsapp + 91 6283343851</Link></li>
                  </ul>
                  <ul>
                    <li> <Link onClick={() => window.scrollTo(0,0) } to="/privacy-policy"><div className='footer__middle_img'><img src={policyIcon} alt='nimbusbytes' /></div> Privacy Policy</Link></li>
                    <li><Link  onClick={() => window.scrollTo(0,0) } to="/legal-notice"><div className='footer__middle_img'><img src={conditionIcon} alt='nimbusbytes' /></div><div> Legal Notice <div> Terms and Conditions of Use</div> </div></Link> </li>
                  </ul>
                </div>
              </div>


              <div className="footer__social">
                <h3>Follow Us</h3>
                <ul>
                  <li><Link  to="/"><img src={linkedInIcon} alt="demo" /></Link></li>
                  <li><Link  to="/"><img src={facebookIcon} alt="demo" /></Link></li>
                  <li><Link  to="/"><img src={instagramIcon} alt="demo" /></Link></li>
                  <li><Link  to="/"><img src={youtubeIcon} alt="demo" /></Link></li>
                </ul>
              </div>
            </div>
          </div>

        </footer>
        <div className="footer__copyright">
          Copyright © 2024 NimbusBytes.
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
