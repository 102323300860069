import React from 'react'
import './securityCards.css'
import BlueCircle from './BlueCircle';
import TextReveal from '../TextReveal/TextReveal';
import { Link } from 'react-router-dom';
import { TiArrowForward } from "react-icons/ti";

const SecurityCards = ({ title, titleDesp, redTitle, desp, securityCard, lockBg,title2,setActiveLink }) => {

    console.log('security cards');
    return (
        <div className='security__cards'>

            <div className="security__banner">
            {/* <BlueArrow ref={svgRef} /> */}
                
                <div className="security__banner_left">
                    <div className="header__left">
                        <h3 className='main'>{title} {titleDesp ? <span className='title__small'>{titleDesp}</span> : ''} {redTitle ? <span className='redTitle'>{redTitle}</span> : ''} {title2}</h3>
                        <p>{desp}</p>
                    </div>
                    <div className="header__right">
                        <Link onClick={() => {window.scrollTo(0,0) ; setActiveLink('/contactus') } } to="/contactus" className='contactBtn red-btn'>Contact Us</Link>
                    </div>

                </div>
                <div className="security__banner_right">
                    {
                        securityCard.map((data, index) => (
                            <div key={index} className="security__card">
                                { data.link ? ( <div className='redirect'><TiArrowForward /></div> ) : "" }
                                <div className="security__card-img">
                                <img src={data.img} alt="demo" />
                                </div>
                                <TextReveal>
                                <div className="security__content">
                                    <h3>{data.title}</h3>
                                    <p>{data.desp}</p>
                                </div>
                                </TextReveal>
                                { data.link ?  <Link to={data.link} onClick={() => {window.scrollTo(0,0)}} /> : ""}
                            </div>
                        ))
                    }
                    <BlueCircle top="26%" left="-10%" />
                    <BlueCircle top="0%" right="0%" />
                    <BlueCircle top="80%" left="-10%" />
                    <BlueCircle top="50%" right="-10%" />
                </div>
            </div>
            {lockBg ? <img src={lockBg} className='lockBg' alt="nimbusbytes" /> : ""}
        </div>
    )
}

export default SecurityCards