import React from 'react'
import OurClients from '../../components/OurClients/OurClients'
import Testimonial from '../../components/Testimonial/Testimonial'
import ContactUs from '../../components/ContactUs/ContactUs'
import SimpleBanner from '../../components/SimpleBanner/SimpleBanner'
import Faqs from '../../components/Faqs/Faqs'
import './services.css'
import CloudImg from '../../images/homepage/cloudIcon.svg'
import cybersecurityIcon from '../../images/homepage/cybersecurityIcon.svg'
import analyticsIcon from '../../images/homepage/analyticsIcon.svg'
import marketingIcon from '../../images/homepage/marketingIcon.svg'
import softwareIcon from '../../images/homepage/softwareIcon.svg'
import modernIcon from '../../images/homepage/modernIcon.svg'
import devopImg from '../../images/homepage/nav4.svg'
import MigrateCards from '../../components/MigrateCards/MigrateCards'

const Services = () => {

  let faqMap = [
    {
      title: "What is Information Technology (IT) Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is a Cloud Computing Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is Cybersecurity Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is a Data and Analytics Service?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "How Much does it cost to start with IT services?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "What is your pricing model?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "Which tools, technologies, and frameworks do you support?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
    {
      title: "Who Is NimbusBytes?",
      des: "Empower innovation and enhance your process workflow performance with our high-quality IT services. "
    },
  ]

  var migrateCards = [
    {
      img: CloudImg,
      title: "Cloud-Managed Services",
      desp: "Leverage our cloud-managed services, ensuring seamless operations and scalability. Our global team of experts manages the complexities of cloud infrastructure, allowing you to focus on innovation and growth.",
      link: "/services/cloud"
    },
    {
      img: cybersecurityIcon,
      title: "Cybersecurity",
      desp: "Prevent your digital assets from data leaks or vulnerabilities with our advanced Cybersecurity services and solutions. From threat detection to risk mitigation, we strengthen your security, providing a secure foundation for your business.",
      link: "/services/cybersecurity"
    },
    {
      img: analyticsIcon,
      title: "Data and Analytics",
      desp: "Turn data into actionable insights with our Data and Analytics service. Lead your competitors in the IT services landscape by making informed decisions and leveraging the full potential of your information assets.",
      link: "/services/analytics"
    },

    {
      img: marketingIcon,
      title: "Digital Marketing",
      desp: "Increase your online presence and engagement with our digital marketing services. From strategic campaigns to targeted conversations, we help you connect with your audience in an ever-expanding digital landscape.",
      link: "/services/digital-marketing"
    },
    {
      img: softwareIcon,
      title: "Software Development Services",
      desp: "Turn your ideas into reality with our software development services. Our customized and comprehensive software development solutions align with your business goals, ensuring efficient and effective software that accelerates your organization's operations.",
      link: "/services/software-services"
    },
    {
      img: modernIcon,
      title: "Migrate and Modernize",
      desp: "Seamlessly move to cloud computing platforms with our Migrate and Modernize services. We enhance efficiency, increase agility, and drive your business to a successful digital transformation. Trust us to set up your infrastructure in the cloud computing platforms smoothly.",
      link: "/services/migrate-modernize"
    },

    {
      img: devopImg,
      title: "DevSecOps and SRE Service",
      desp: "Liberate traditional practices and go to market faster as you discover a new world of business value and maximize your business costs with our DevSecOps and SRE services. With us, you can achieve.",
      link: "/services/devops"
    },

  ]

  


  return (
    <div className='services'>

      <div className="title__banner ">
        <h2 className='main'>Services</h2>
      </div>

      <MigrateCards cardData={migrateCards} />


      <OurClients />

      <Testimonial />

      <Faqs faqMap={faqMap} title="FAQ" desp="Empower innovation and enhance your process workflow performance with our high-quality IT services." />

      <ContactUs />

      <SimpleBanner title1="Book Your" redText="FREE" topDesp="Discuss Your Needs."
        btnText="Let 's  Connect" title="30-Minute Consultation." link="/contactus" />

    </div>
  )
}

export default Services